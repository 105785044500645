@font-face {
  font-family: "FontAwesome";
  src: url(../fonts/fontawesome-webfont.ttf) format("truetype");
}

.widget-body {
  margin-top: 10px;
}

textarea.form-control {
  min-height: 60px !important;
}
