@font-face {
  font-family: "FontAwesome";
  src: url(../fonts/fontawesome-webfont.ttf) format("truetype");
}

body.light-login {
  // background: url("../../images/background01.jpg") repeat scroll 0 0;
}

.widget-body {
  margin-top:10px;
}

textarea.form-control {
  min-height: 60px !important;
}